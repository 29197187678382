<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>NF-e's com status "Aceita" podem ser adicionadas aqui para conferência dos boletos/produtos entregues. Atualiza o status da nota para "Recebimento - Entregue".</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body">
               <div class="row">
                  <div class="col-9 col-md-10 col-lg-11">
							<label><i class="far fa-barcode color-theme font-11 me-1"></i> Chave da nota</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus @keyup.enter="buscarNotas" />
                  </div>
                  <div class="col-3 col-md-2 col-lg-1 align-self-end">
							<button type="button" class="btn btn-primary w-100" @click="buscarNotas"><i class="fas fa-search font-13"></i></button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
         <div class="cards-150 cards-sm-225 cards-lg-250">
            <nota v-for="(nota, index) in pesquisa.retorno.resultado" :key="index" :nota="nota" :index="index" :showCancelar="false" @visualizar="buscarNota($event)" />
         </div>
      </div>
   </div>
</template>

<script>

import router from '@/router'
import { mapState } from 'vuex'
import nota from '@/components/modelos/Nota.vue'

export default {
	name: 'Entrada_de_notas',
	data: function () {
		return {
			pesquisa: {'status': 'Recebimento - Entregue', 'loja': null, 'nome': '', 'data': [new Date(), new Date()], 'retorno': {'status': null, 'loja': null, 'nome': '', 'resultado': []}}
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
	watch: {
		'$route.params.idTab': function () {
         this.buscarNotas()
      }
	},
	components: {
		nota
	},
	methods: {
		buscarNotas : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

			this.$axios({
				method: 'post',
				url: this.urlRest +'recebimento/searchNotas',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarNota : function (nota) {
			let tabNota = this.tabs.find(tab => tab.nome == 'Entrada_de_mercadorias' && tab.query.chaveNota == nota.chave)
			router.push('/entradaMercadorias/'+ (tabNota ? tabNota.id : this.tabs.length) +'?chaveNota='+ nota.chave +'&view=Entrada')
		}
	},
	mounted() {
      this.buscarNotas()
	}
}

</script>